








































































































































































































.discount-coupon {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.inner-content {
  margin: 20px;
  flex: 1;
  height: 1%;
  display: flex;
  flex-direction: column;
  position: relative;
}

::v-deep .coupon-list .el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: #262963;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #101341;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #101341;
  }
}
.create-coupon-bag {
  width: 178px;
  height: 94px;

  .create-bag-button {
    width: 178px;
    height: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border: 0;
    background: url("../../../../assets/image/studentCoupon/studentCoupon.png");
    cursor: pointer;
  }
  .iconfont {
    display: block;
    width: 40px;
    height: 39px;
    margin: 10px 0 8px 60px;
    font-size: 36px;
  }
}
.coupon-list {
  height: 1%;
  flex: 1;
  margin-top: 22px;
  .goods-wrapper {
    display: flex;
    align-items: center;
    .goods-cover {
      width: 50px;
      height: 50px;
      display: flex;
      background: #f6f6f6;
      border-radius: 8px;
      img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
    .goods-name {
      width: 1%;
      flex: 1;
      margin-left: 10px;
      font-size: 14px;
    }
  }
}
.el-table {
  position: absolute;
  width: 100%;

  &:before {
    content: none;
  }

  ::v-deep.el-table__empty-block {
    background: #070932;

    .el-table__empty-text {
      color: #fff;
    }
  }

  ::v-deep .el-table__body-wrapper {
    background: #070932;
  }

  ::v-deep th.el-table__cell.is-leaf {
    border-bottom: none;
  }

  ::v-deep td.el-table__cell {
    border-bottom: 1px solid #262963;

    &:first-child {
      border-left: 1px solid #262963;
    }

    &:last-child {
      border-right: 1px solid #262963;
    }
  }
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #0e0c3f !important;
}
